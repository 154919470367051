






















































import { User } from '@/store/models';
import { getEnv } from '@/util/env';
import Vue from 'vue';

export default Vue.extend({
  name: 'HskAbout',
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    currentUser(): User {
      return this.$store.getters.currentUser;
    },
    backendUrl(): string {
      return getEnv('VUE_APP_BACKEND_URL') as string
    },
    hasHttps(): boolean {
      return this.backendUrl.startsWith('https');
    },
    referenceBase(): string {
      return this.backendUrl.replace(/^https?:\/\//, '').replace(/\/$/, '');
    },
  },
});
