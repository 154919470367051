















































































































import { Image, Manifest } from '@/store/models';
import DockerDetails from '@/components/DockerDetails.vue';
import SingularityDetails from '@/components/SingularityDetails.vue';
import OrasDetails from '@/components/OrasDetails.vue';
import Vue from 'vue';

interface State {
  image?: Image;
  downloadCount: number;
}

export default Vue.extend({
  components: { DockerDetails, SingularityDetails, OrasDetails },
  name: 'ManifestDetails',
  props: {
    manifest: {
      type: Manifest,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data: (): { localState: State } => ({
    localState: {
      image: undefined,
      downloadCount: 0,
    },
  }),
  mounted: function() {
    this.localState.downloadCount = this.manifest.downloadCount;
    if (this.manifest.type !== 'singularity') {
      return;
    }
    this.$store.dispatch('images/get', { path: this.manifest.path, tag: this.manifest.content.layers[0].digest.replace('sha256:', 'sha256.')  })
      .then(image => {
        this.localState.image = image;
      })
      .catch(err => {
        this.$store.commit('snackbar/showError', err);
      });
  },
  computed: {
    badgeColor() {
      return this.localState.downloadCount ?
        'blue darken-1' : 'blue-grey lighten-1'
    }
  },
  methods: {
    copyTag(tag: string) {
      this.$copyText(this.manifest.pullCmd(tag))
        .then(() => this.$store.commit('snackbar/showSuccess', "Copied to clipboard"))
    },
    downloadManifest() {
      this.$store.dispatch('tokens/requestDownload', { id: this.manifest.id, type: 'manifest' })
        .then((location: string) => {
          window.location.href=location;
          // cheating
          this.localState.downloadCount++;
        })
        .catch(err => {
          this.$store.commit('snackbar/showError', err);
        });
    },
    copyDownload(what: string | null = null) {
      this.$store.dispatch('tokens/requestDownload', { id: this.manifest.id, type: 'manifest' })
        .then((location: string) => {
          switch(what) {
            case 'curl':
              location = `curl -JOf ${location}`;
              break;
            case 'wget':
              location = `wget -O ${this.manifest.filename} ${location}`
              break;
          }
          this.$copyText(location).then(() => this.$store.commit('snackbar/showSuccess', "Copied to clipboard"))
        })
        .catch(err => {
          this.$store.commit('snackbar/showError', err);
        })
    }
  },
});
