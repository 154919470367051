












































































































































import ImageDetails from '@/components/ImageDetails.vue';
import ManifestDetails from '@/components/ManifestDetails.vue';

import Vue from 'vue';
import { Container, Image, Manifest, User } from '../store/models';

import { orderBy as _orderBy } from 'lodash';

interface State {
  container: Container | null;
  error: string | null;
}

export default Vue.extend({
  components: { ImageDetails, ManifestDetails, },
  name: 'ContainerDetails',
  mounted() {
    this.loadContainer();
  },
  data: (): { localState: State } => ({
    localState: {
      container: null,
      error: null,
    }
  }),
  watch: {
    $route() {
      this.loadContainer();
    }
  },
  computed: {
    title(): string {
      return `${this.localState.container ? this.localState.container.fullPath : '...'}`
    },
    loading(): boolean {
      return this.$store.getters['container/status']==='loading';
    },
    images(): Image[] {
      return _orderBy(this.$store.getters['images/list'], 'createdAt', 'desc');
    },
    manifests(): Manifest[] {
      return _orderBy(this.$store.getters['manifests/list'], 'createdAt', 'desc');
    },
    currentUser(): User {
      return this.$store.getters.currentUser;
    },
    canEdit(): boolean {
      return this.localState.container !== null && !this.localState.container.readOnly && this.localState.container.canEdit
    }
  },
  methods: {
    loadContainer() {
      this.localState.error = null;
      this.$store.dispatch('containers/get', { entityName: this.$route.params.entity, collectionName: this.$route.params.collection, containerName: this.$route.params.container })
        .then((container: Container) => {
          this.localState.container = container;
          if (container.type === 'singularity') {
            this.loadImages();
          }
          else {
            this.loadManifests();
          }
        })
        .catch(err => {
          this.localState.error = err;
        });
    },
    loadManifests() {
      this.$store.dispatch('manifests/list', this.localState.container)
        .catch(err => {
          this.$store.commit('snackbar/showError', err);
        });
    },
    loadImages() {
      this.$store.dispatch('images/list', this.localState.container)
        .catch(err => {
          this.$store.commit('snackbar/showError', err);
        });
    }
  },
});
