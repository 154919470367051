



















import { Manifest } from '@/store/models'; 
import Vue from 'vue';

export default Vue.extend({
  name: 'OrasDetails',
  props: {
    manifest: {
      type: Manifest,
      required: true,
    },
  },
  computed: {
    digest: function(): string {
      if (!this.manifest.content.layers) {
        return '';
      }
      return this.manifest.content.layers[0].digest || '';
    }
  }
});
