var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tokens"},[_c('top-bar',{attrs:{"title":"Tokens"}}),_c('v-container',[(_vm.activeUser)?_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('h1',[_vm._v("You are looking at "+_vm._s(_vm.activeUser.fullname)+"s tokens")])])],1):_vm._e(),(_vm.localState.createdToken)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"10","offset-md":"1"}},[_c('v-alert',{attrs:{"prominent":"","type":"info","border":"left","dismissible":""}},[_c('div',{staticClass:"text-h5"},[_vm._v(" Here is your new token! ")]),_c('div',[_c('code',{staticClass:"generatedToken"},[_vm._v(_vm._s(_vm.localState.createdToken.generatedToken)+" "),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.copyToken(_vm.localState.createdToken)}}},[_c('v-icon',[_vm._v("mdi-content-copy")])],1)],1)]),_c('div',[_vm._v(" Please commit it to memory or entrust it to your favorite password manager. You will never see it again! ")])])],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"10","offset-md":"1"}},[_c('v-data-table',{attrs:{"id":"tokens","headers":_vm.headers,"items":_vm.tokens,"search":_vm.localState.search,"sort-by":_vm.localState.sortBy,"sort-desc":_vm.localState.sortDesc,"loading":_vm.loading},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-magnify","label":"Search...","single-line":"","outlined":"","dense":"","hide-details":""},model:{value:(_vm.localState.search),callback:function ($$v) {_vm.$set(_vm.localState, "search", $$v)},expression:"localState.search"}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dense":"","depressed":""}},'v-btn',attrs,false),on),[_vm._v("Create Token")])]}}]),model:{value:(_vm.localState.showEdit),callback:function ($$v) {_vm.$set(_vm.localState, "showEdit", $$v)},expression:"localState.showEdit"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(_vm._s(_vm.editTitle))]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Comment"},model:{value:(_vm.localState.editItem.comment),callback:function ($$v) {_vm.$set(_vm.localState.editItem, "comment", $$v)},expression:"localState.editItem.comment"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":"","min-width":"200px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-icon":"mdi-calendar","readonly":"","label":"Expiration"},model:{value:(_vm.editExpiration),callback:function ($$v) {_vm.editExpiration=$$v},expression:"editExpiration"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.localState.showExpiration),callback:function ($$v) {_vm.$set(_vm.localState, "showExpiration", $$v)},expression:"localState.showExpiration"}},[_c('v-date-picker',{on:{"input":function($event){_vm.localState.showExpiration=false}},model:{value:(_vm.editExpiration),callback:function ($$v) {_vm.editExpiration=$$v},expression:"editExpiration"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"warning accent-1","text":""},on:{"click":_vm.closeEdit}},[_vm._v("Maybe not today.")]),_c('v-btn',{attrs:{"color":"primary darken-1","text":""},on:{"click":_vm.save}},[_vm._v("Save it!")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.localState.showDelete),callback:function ($$v) {_vm.$set(_vm.localState, "showDelete", $$v)},expression:"localState.showDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("You really want to kick it?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Give it another chance.")]),_c('v-btn',{attrs:{"color":"warning accent-1","text":""},on:{"click":_vm.deleteTokenConfirm}},[_vm._v("Get it out of my eyes.")])],1)],1)],1),_c('v-btn',{staticClass:"ml-2",attrs:{"id":"refresh","dense":"","depressed":""},on:{"click":function($event){return _vm.loadTokens()}}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1)]},proxy:true},{key:"item.token",fn:function(ref){
var item = ref.item;
return [_c('code',[_vm._v(_vm._s(item.key_uid))])]}},{key:"item.expiresAt",fn:function(ref){
var item = ref.item;
return [(item.expiresAt)?_c('span',[_vm._v(" "+_vm._s(_vm._f("prettyDateTime")(item.expiresAt))+" ")]):_c('span',[_c('em',[_vm._v("no expiration")])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editToken(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteToken(item)}}},[_vm._v(" mdi-delete ")])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }