



















































































































































































































import { LdapStatus, LdapPing, AdmLdapSyncResults, Job } from '@/store/models';
import Vue from 'vue';

export default Vue.extend({
  name: 'HskLdap',
  mounted() {
    this.loadStatus();
    this.loadSyncResults();
  },
  data: () => ({
    showSyncProgress: false,
  }),
  computed: {
    loading(): boolean {
      return this.$store.getters['adm/status'] === 'loading';
    },
    ldap(): LdapStatus | null {
      return this.$store.getters['adm/ldapStatus'];
    },
    pingResult(): LdapPing | null {
      return this.$store.getters['adm/ldapPing'];
    },
    pingColor(): string {
      return !this.pingResult ? '' 
        : this.pingResult.status === 'ok' ? 'success lighten-1'
        : 'error lighten-1';
    },
    pingIcon(): string | null {
      return !this.pingResult ? null
        : this.pingResult.status === 'ok' ? 'mdi-check-circle'
        : 'mdi-alert-circle'
    },
    ldapSyncResults(): AdmLdapSyncResults | null {
      return this.$store.getters['adm/ldapSyncResults'];
    },
    ldapSyncJob(): Job | null {
      return this.$store.getters['adm/ldapSyncJob'];
    },
    ldapSyncColor(): string {
      return !this.ldapSyncJob || this.ldapSyncJob.status === 'queued' ? ''
        : this.ldapSyncJob.status === 'started' ? 'indigo lighten-1' 
        : this.ldapSyncJob.status === 'finished' ? 'success lighten-1'
        : this.ldapSyncJob.status === 'failed' ? 'error lighten-1'
        : this.ldapSyncJob.status === 'deferred' ? 'warning lighten-1'
        : 'grey lighten-1'
    },
    ldapSyncIcon(): string | null {
      return !this.ldapSyncJob ? null
        : this.ldapSyncJob.status === 'queued' ? 'mdi-timer-sand' 
        : this.ldapSyncJob.status === 'started' ? 'mdi-run' 
        : this.ldapSyncJob.status === 'finished' ? 'mdi-check-circle'
        : this.ldapSyncJob.status === 'failed' ? 'mdi-alert-circle'
        : this.ldapSyncJob.status === 'deferred' ? 'mdi-sleep'
        : 'mdi-progress-question';
    },
  },
  methods: {
    loadStatus() {
      this.$store.dispatch('adm/ldapStatus')
        .catch(err => this.$store.commit('snackbar/showError', err))
    },
    ping() {
      this.$store.dispatch('adm/ldapPing')
        .catch(err => this.$store.commit('snackbar/showError', err))
    },
    loadSyncResults() {
      this.$store.dispatch('adm/ldapSyncResults')
        .catch(err => this.$store.commit('snackbar/showError', err))
    },
    startSync() {
      this.showSyncProgress = true;
      this.$store.dispatch('adm/syncLdap')
        .then(() => {
          const pollLdapJob = () => {
            this.$store.dispatch('adm/syncLdapStatus')
              .then(res => {
                if (res.status !== 'finished' && res.status !== 'failed') {
                  setTimeout(pollLdapJob, 1000);
                }
                else if (res.status === 'finished') {
                  this.loadSyncResults();
                }
              })
              .catch(err => this.$store.commit('snackbar/showError', err))
          };
          setTimeout(pollLdapJob, 1000);
        })
        .catch(err => this.$store.commit('snackbar/showError', err));
    }
  }
});
