


















































































































import { AxiosError } from 'axios';
import Vue from 'vue';
import { LdapStatus, User } from './store/models';

import { SnackbarType } from './store/modules/snackbar';

export default Vue.extend({
  name: 'App',
  created: function () {
    const { $store, $router } = this;
    this.$store.commit('registerInterceptor', (err: AxiosError) => {
      return new Promise(() => {
        if (err.response && err.response.status === 401 && err.config) {
          $store.dispatch('logout');
          if ($router.currentRoute.fullPath !== '/login') {
            $router.push('/login');
          }
        }
        throw err;
      });
    });
    if (this.isLoggedIn && this.currentUser.isAdmin) {
      this.$store.dispatch('adm/ldapStatus')
        .catch(err => {
          this.$store.commit('snackbar/showError', err);
        });
    }
  },
  computed: {
    isLoggedIn(): boolean {
      return this.$store.getters.isLoggedIn;
    },
    currentUser(): User {
      return this.$store.getters.currentUser;
    },
    showSnackbar: {
      get: function(): boolean {
        return this.$store.getters['snackbar/show'];
      },
      set: function(newValue: boolean) {
        if (!newValue) {
          this.$store.commit('snackbar/close');
        }
      },
    },
    snackbarMsg(): string {
      return this.$store.getters['snackbar/msg'];
    },
    snackbarColor(): string {
      const type: SnackbarType = this.$store.getters['snackbar/type'];
      const map: { [key: string]: string } = {
        '': 'blue-grey',
        'info': 'blue-grey',
        'success': 'success',
        'error': 'error',
      };
      return map[type];
    },
    snackbarTimeout(): number {
      return this.$store.getters['snackbar/type'] === 'error' ? -1 : 5000;
    },
    ldapStatus(): LdapStatus {
      return this.$store.getters['adm/ldapStatus'];
    },
    showDrawer(): boolean {
      return this.$store.getters.showDrawer;
    }
  },
  methods: {
    hideSnackbar() {
      this.$store.commit('snackbar/close');
    },
    logout() {
      this.$store.dispatch('logout')
        .then(() => this.$router.push('/login'));
    }
  }
});
