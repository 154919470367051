














































































































































import Vue from 'vue';
import { DataTableHeader } from 'vuetify';
import { Entity, Group, GroupMember, GroupRoles, User } from '../store/models';
import UserInput from '@/components/UserInput.vue';
import { concat as _concat, filter as _filter } from 'lodash';

interface State {
  group: Group | null;
  entity: Entity | null;
  error: string | null;
  search: string;
  sortBy: string;
  sortDesc: boolean;
  editValid: boolean;
  showAdd: boolean;
  editItem: GroupMember;
}

function defaultItem(): GroupMember {
  const item = new GroupMember();
  item.role = GroupRoles.contributor;
  item.user = new User();
  item.user.username = '';
  return item;
}

export default Vue.extend({
  name: 'GroupDetails',
  components: { 'hsk-user-input': UserInput },
  mounted() {
    this.loadGroup();
  },
  data: (): { headers: DataTableHeader[]; localState: State } => ({
    headers: [
      { text: 'Username', value: 'user.username', sortable: true },
      { text: 'Name', value: 'user.lastname', sortable: true },
      { text: 'Role', value: 'role', sortable: true },
      { text: 'Actions', value: 'actions', sortable: false, filterable: false, width: '10%' },
    ],
    localState: {
      group: null,
      entity: null,
      error: null,
      search: '',
      sortBy: 'user.username',
      sortDesc: false,
      showAdd: false,
      editValid: true,
      editItem: defaultItem(),
    }
  }),
  watch: {
    $route() {
      this.loadGroup();
    }
  },
  computed: {
    loading(): boolean {
      return this.$store.getters['groups/status']==='loading';
    },
    currentUser(): User {
      return this.$store.getters.currentUser;
    },
    availableRoles(): Record<string, unknown>[] {
      return Object.keys(GroupRoles).map(r => {
        return { value: r, text: r };
      });
    }
  },
  methods: {
    loadGroup() {
      this.localState.error = null;
      this.$store.dispatch('groups/get', this.$route.params.group)
        .then((group: Group) => {
          this.localState.group = group;
          return this.$store.dispatch('entities/get', group.entityRef)
        })
        .then((entity: Entity) => {
          this.localState.entity = entity;
        })
        .catch(err => {
          this.localState.error = err;
        });
    },
    closeAdd() {
      this.localState.showAdd = false;
      this.$nextTick(() => {
        this.localState.editItem = defaultItem();
      });
    },
    _saveMembers(): Promise<GroupMember[]> {
      return this.$store.dispatch('groups/setMembers', this.localState.group)
        .then(members => {
          this.$store.commit('snackbar/showSuccess', 'Hooray, Success!');
          return members;
        })
        .catch(err => this.$store.commit('snackbar/showError', err));
    },
    deleteUser(ug: GroupMember) {
      if (!this.localState.group) return;
      this.localState.group.users = 
        _filter(this.localState.group.users, l => l.user.username !== ug.user.username);
      this._saveMembers();
    },
    updateRole(upd: GroupMember) {
      if (!this.localState.group) return;
      this.localState.group.users = _concat(
        _filter(this.localState.group.users, l => l.user.username !== upd.user.username),
        upd
      );
      this._saveMembers();
    },
    addUser() {
      if (!this.localState.group) return;
      if (this.localState.editItem.user.username) {
        this.localState.group.users = 
          _concat(this.localState.group.users, this.localState.editItem);
      }
      this._saveMembers();
      this.closeAdd();
    }
  }
})
