










































































































































































































import { Collection, Entity, User, checkName } from '../store/models';
import Vue from 'vue';
import { clone as _clone } from 'lodash';
import UserInput from '@/components/UserInput.vue';

interface State {
  search: string;
  sortBy: string;
  sortDesc: boolean;
  editItem: Collection;
  editValid: boolean;
  showEdit: boolean;
  showDelete: boolean;
}

function defaultItem(): Collection {
  const item = new Collection();
  item.createdAt = new Date();
  return item;
}

export default Vue.extend({
  name: 'HskCollections',
  components: { 'hsk-user-input': UserInput },
  mounted() {
    this.loadCollections();
  },
  data: (): { localState: State; sortKeys: { key: string; desc: string }[] } => ({
    localState: {
      search: '',
      sortBy: 'name',
      sortDesc: false,
      showEdit: false,
      showDelete: false,
      editItem: defaultItem(),
      editValid: true,
    },
    sortKeys: [
      { key: 'name', desc: 'Name' }, 
      { key :'createdAt', desc: "Create Date" }, 
      { key: 'updatedAt', desc: "Last Updated" },
      { key: 'size', desc: '# Containers' },
      { key: 'usedQuota', desc: 'Size' },
    ],
  }),
  computed: {
    collections(): Collection[] {
      return this.$store.getters['collections/list'];
    },
    loading(): boolean {
      return this.$store.getters['collections/status']==='loading';
    },
    editTitle(): string {
      return this.localState.editItem.id ? 'Edit Collection' : 'New Collection';
    },
    entity(): Entity {
      return this.$store.getters['collections/currentEntity'];
    },
    currentUser(): User {
      return this.$store.getters.currentUser;
    }
  },
  watch: {
    $route() {
      this.loadCollections();
    },
    'localState.showEdit': function showEdit(val) {
      if (!val) {
        this.closeEdit();
      }
    },
    'localState.showDelete': function showDelete(val) {
      if (!val) {
        this.closeDelete();
      }
    },
  },
  methods: {
    loadCollections() {
      this.$store.dispatch('collections/list', this.$route.params.entity)
        .catch(err => this.$store.commit('snackbar/showError', err));
    },
    editCollection(collection: Collection) {
      this.localState.editItem = _clone(collection);
      this.localState.showEdit = true;
    },
    deleteCollection(collection: Collection) {
      this.localState.editItem = _clone(collection);
      this.localState.showDelete = true;
    },
    deleteCollectionConfirm() {
      this.$store.dispatch('collections/delete', this.localState.editItem)
        .then(() => this.$store.commit('snackbar/showSuccess', "It's gone!"))
        .catch(err => this.$store.commit('snackbar/showError', err));
      this.closeDelete();
    },
    closeEdit() {
      this.localState.showEdit = false;
      this.$nextTick(() => {
        this.localState.editItem = defaultItem();
      });
    },
    closeDelete() {
      this.localState.showDelete = false;
      this.$nextTick(() => {
        this.localState.editItem = defaultItem();
      });
    },
    save() {
      const action = this.localState.editItem.id ?
        'collections/update' : 'collections/create';
      if (this.$route.params.entity) {
        this.localState.editItem.entityName = this.$route.params.entity;
      }
      this.$store.dispatch(action, this.localState.editItem)
        .then(() => this.$store.commit('snackbar/showSuccess', 'Yay!'))
        .catch(err => this.$store.commit('snackbar/showError', err));
      this.closeEdit();
    },
    checkName(name: string): string | boolean {
      return checkName(name);
    },
  }
});
