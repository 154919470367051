




























































































































































































import Vue from 'vue';
import { getEnv } from '@/util/env';

import LatestContainers from '@/components/LatestContainers.vue';
import StarredContainers from '@/components/StarredContainers.vue';
import { User } from '@/store/models';

interface State {
  usageTab: any;
}

export default Vue.extend({
  name: 'HskHome',
  data: (): { localState: State } => ({
    localState: {
      usageTab: 0,
    },
  }),
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    currentUser(): User {
      return this.$store.getters.currentUser;
    },
    backendUrl(): string {
      return getEnv('VUE_APP_BACKEND_URL') as string
    },
    hasHttps(): boolean {
      return this.backendUrl.startsWith('https');
    },
    referenceBase(): string {
      return this.backendUrl.replace(/^https?:\/\//, '').replace(/\/$/, '');
    },
  },
  components: {
    LatestContainers,
    StarredContainers,
  },
});
