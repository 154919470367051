







































































































































































































import Vue from 'vue';
import { checkName, Group, GroupRoles, User } from '../store/models';
import { clone as _clone } from 'lodash';
import UserInput from '@/components/UserInput.vue';

interface State {
  editItem: Group;
  showEdit: boolean;
  editValid: boolean;
  showDelete: boolean;
  search: string,
  sortBy: string;
  sortDesc: boolean;
}

function defaultItem(): Group {
  const item = new Group();
  item.createdAt = new Date();
  return item;
}

export default Vue.extend({
  name: 'HskGroups',
  components: { 'hsk-user-input': UserInput },
  mounted() {
    this.loadGroups();
  },
  data: (): { localState: State; sortKeys: { key: string; desc: string }[] } => ({
    localState: {
      showEdit: false,
      showDelete: false,
      editItem: defaultItem(),
      editValid: true,
      search: '',
      sortBy: 'name',
      sortDesc: false,
    },
    sortKeys: [
      { key: 'name', desc: 'Name' },
      { key: 'createdAt', desc: 'Create Date' },
      { key: 'updatedAt', desc: 'Last Updated' },
    ]
  }),
  computed: {
    groups(): Group[] {
      return this.$store.getters['groups/list'];
    },
    loading(): boolean {
      return this.$store.getters['groups/status']==='loading';
    },
    editTitle(): string {
      return this.localState.editItem.id ? 'Edit Group' : 'New Group';
    },
    currentUser(): User {
      return this.$store.getters.currentUser;
    },
  },
  watch: {
    'localState.showEdit': function showEdit(val) {
      if (!val) {
        this.closeEdit();
      }
    },
    'localState.showDelete': function showDelete(val) {
      if (!val) {
        this.closeDelete();
      }
    },
  },
  methods: {
    loadGroups() {
      this.$store.dispatch('groups/list')
        .catch(err => this.$store.commit('snackbar/showError', err));
    },
    editGroup(group: Group) {
      this.localState.editItem = _clone(group);
      this.localState.showEdit = true;
    },
    deleteGroup(group: Group) {
      this.localState.editItem = _clone(group);
      this.localState.showDelete = true;
    },
    deleteConfirm() {
      this.$store.dispatch('groups/delete', this.localState.editItem)
        .then(() => this.$store.commit('snackbar/showSuccess', "It's gone!"))
        .catch(err => this.$store.commit('snackbar/showError', err));
    },
    closeEdit() {
      this.localState.showEdit = false;
      this.$nextTick(() => {
        this.localState.editItem = defaultItem();
      });
    },
    closeDelete() {
      this.localState.showDelete = false;
      this.$nextTick(() => {
        this.localState.editItem = defaultItem();
      });
    },
    save() {
      const action = this.localState.editItem.id ? 
        'groups/update' : 'groups/create';
      this.$store.dispatch(action, this.localState.editItem)
        .then(() => this.$store.commit('snackbar/showSuccess', 'Yay!'))
        .catch(err => this.$store.commit('snackbar/showError', err));
      this.closeEdit();
    },
    checkName(name: string): string | boolean {
      return checkName(name);
    },
    roleIcon(group: Group): string {
      const role = group.getRole(this.currentUser);
      switch(role) {
        case GroupRoles.admin:
          return 'mdi-book-account';
        case GroupRoles.contributor:
          return 'mdi-book-plus';
        case GroupRoles.readonly:
          return 'mdi-book-lock';
        default:
          return 'mdi-head-question';
      }
    }
  }
});

