

























































































































































































































































import Vue from 'vue';
import { clone as _clone, } from 'lodash';

import { Container, Collection, User, checkName } from '../store/models';
import UserInput from '@/components/UserInput.vue';
import ContainerType from '@/components/ContainerType.vue';

interface State {
  search: string;
  sortBy: string;
  sortDesc: boolean;
  editItem: Container;
  editValid: boolean;
  showEdit: boolean;
  showDelete: boolean;
  deleteCascade: boolean;
}

function defaultItem(): Container {
  const item = new Container();
  item.createdAt = new Date();
  return item;
}


export default Vue.extend({
  name: 'HskContainers',
  components: { 'hsk-user-input': UserInput, ContainerType },
  mounted() {
    this.loadContainers();
  },
  data: (): { localState: State; sortKeys: {key: string; desc: string}[] } => ({
    localState: {
      search: '',
      sortBy: 'name',
      sortDesc: false,
      showEdit: false,
      showDelete: false,
      deleteCascade: false,
      editItem: defaultItem(),
      editValid: true,
    },
    sortKeys: [
      { key: 'name', desc: 'Name' }, 
      { key :'createdAt', desc: "Create Date" }, 
      { key: 'updatedAt', desc: "Last Updated" },
      { key: 'downloadCount', desc: "Downloads" },
      { key: 'stars', desc: "Stars" },
      { key: 'size', desc: '# Images' } ,
      { key: 'usedQuota', desc: 'Size' },
    ],
  }),
  computed: {
    containers(): Container[] {
      return this.$store.getters['containers/list'];
    },
    collection(): Collection {
      return this.$store.getters['containers/currentCollection'];
    },
    loading(): boolean {
      return this.$store.getters['containers/status']==='loading';
    },
    editTitle(): string {
      return this.localState.editItem.id ? 'Edit Container' : 'New Container';
    },
    currentUser(): User {
      return this.$store.getters.currentUser;
    },
  },
  watch: {
    $route() {
      this.loadContainers();
    },
    'localState.showEdit': function showEdit(val) {
      if (!val) {
        this.closeEdit();
      }
    },
    'localState.showDelete': function showDelete(val) {
      if (!val) {
        this.closeDelete();
      }
    },
  },
  methods: {
    loadContainers() {
      this.$store.dispatch('containers/list', { entityName: this.$route.params.entity, collectionName: this.$route.params.collection })
        .catch(err => this.$store.commit('snackbar/showError', err));
    },
    editContainer(container: Container) {
      this.localState.editItem = _clone(container);
      this.localState.showEdit = true;
    },
    deleteContainer(container: Container) {
      this.localState.editItem = _clone(container);
      this.localState.deleteCascade = false;
      this.localState.showDelete = true;
    },
    deleteContainerConfirm() {
      this.$store.dispatch('containers/delete', { container: this.localState.editItem, cascade: this.localState.deleteCascade })
        .then(() => this.$store.commit('snackbar/showSuccess', "It's gone!"))
        .catch(err => this.$store.commit('snackbar/showError', err));
      this.closeDelete();
    },
    closeEdit() {
      this.localState.showEdit = false;
      this.$nextTick(() => {
        this.localState.editItem = defaultItem();
      });
    },
    closeDelete() {
      this.localState.showDelete = false;
      this.$nextTick(() => {
        this.localState.editItem = defaultItem();
      });
    },
    save() {
      const action = this.localState.editItem.id ?
        'containers/update' : 'containers/create';
      this.localState.editItem.entityName = this.$route.params.entity;
      this.localState.editItem.collectionName = this.$route.params.collection;

      this.$store.dispatch(action, this.localState.editItem)
        .then(() => this.$store.commit('snackbar/showSuccess', 'Yay!'))
        .catch(err => this.$store.commit('snackbar/showError', err));
      this.closeEdit();
    },
    follow($event: MouseEvent) {
      $event.stopPropagation();
    },
    checkName(name: string): string | boolean {
      return checkName(name);
    },
  },
});

