



















































































































































import Vue from 'vue';
import { Token, User } from '../store/models';
import { clone as _clone } from 'lodash';
import moment from 'moment';
import { DataTableHeader } from 'vuetify';

interface State {
  search: string;
  sortBy: string;
  sortDesc: boolean;
  showEdit: boolean;
  showDelete: boolean;
  showExpiration: boolean;
  editItem: Token;
  createdToken: Token | null;
}

export default Vue.extend({
  name: 'HskTokens',
  mounted() {
    this.localState.createdToken = null;
    this.loadTokens();
  },
  watch: {
    $route() {
      this.loadTokens();
    },
    'localState.showEdit': function showEdit(val) {
      if (!val) {
        this.closeEdit();
      }
    },
    'localState.showDelete': function showDelete(val) {
      if (!val) {
        this.closeDelete();
      }
    },
  },
  data: (): { headers: DataTableHeader[]; localState: State } => ({
    headers: [
      { text: 'id', value: 'id', sortable: true, filterable: false, width: '9%' },
      { text: 'Key ID', value: 'token', sortable: false, width: '20%' },
      { text: 'Comment', value: 'comment', sortable: true, width: '' },
      { text: 'Expires', value: 'expiresAt', sortable: true, filterable: false, width: '20%' },
      { text: 'Actions', value: 'actions', sortable: false, filterable: false, width: '1%' },
    ],
    localState: {
      search: '',
      showEdit: false,
      showDelete: false,
      showExpiration: false,
      editItem: new Token(),
      sortBy: 'id',
      sortDesc: false,
      createdToken: null,
    },
  }),
  computed: {
    tokens(): Token[] {
      return this.$store.getters['tokens/tokens'];
    },
    loading(): boolean {
      return this.$store.getters['tokens/status']==='loading';
    },
    editTitle(): string {
      return this.localState.editItem.id ? 'Edit Token' : 'New Token';
    },
    activeUser(): User | null {
      return this.$route.params.user ? this.$store.getters['tokens/user'] : null;
    },
    editExpiration: {
      get: function(): string {
        return this.localState.editItem.expiresAt ? 
          moment(this.localState.editItem.expiresAt).format('YYYY-MM-DD') :
          "";
      },
      set: function(val: string) {
        this.localState.editItem.expiresAt = new Date(val);
      }
    }
  },
  methods: {
    loadTokens() {
      if (this.$route.params.user) {
        this.$store.dispatch('users/get', this.$route.params.user)
          .then(user => {
            this.$store.commit('tokens/setActiveUser', user);
            this.$store.dispatch('tokens/list')
              .catch(err => this.$store.commit('snackbar/showError', err));
          })
          .catch(err => this.$store.commit('snackbar/showError', err));
      }
      else {
        this.$store.commit('tokens/setActiveUser', null);
        this.$store.dispatch('tokens/list')
          .catch(err => this.$store.commit('snackbar/showError', err));
      }
    },
    editToken(token: Token) {
      this.localState.editItem = _clone(token);
      this.localState.showEdit = true;
    },
    deleteToken(token: Token) {
      this.localState.editItem = _clone(token);
      this.localState.showDelete = true;
    },
    deleteTokenConfirm() {
       this.$store.dispatch('tokens/delete', this.localState.editItem.id)
        .then(() => this.$store.commit('snackbar/showSuccess', 'Gone!'))
        .catch(err => this.$store.commit('snackbar/showError', err))
       this.closeDelete();
    },
    closeEdit() {
      this.localState.showEdit = false;
      this.$nextTick(() => {
        this.localState.editItem = new Token();
      });
    },
    closeDelete() {
      this.localState.showDelete = false;
      this.$nextTick(() => {
        this.localState.editItem = new Token();
      });
    },
    save() {
      const action = this.localState.editItem.id ?
        'tokens/update' : 'tokens/create';
      this.$store.dispatch(action, this.localState.editItem)
        .then(upd => {
          this.$store.commit('snackbar/showSuccess', 'Saved!');
          if (action === 'tokens/create') {
            this.localState.createdToken = upd;
          }
        })
        .catch(err => this.$store.commit('snackbar/showError', err))
      this.closeEdit();
    },
    copyToken(item: Token) {
      this.$copyText(item.generatedToken)
        .then(() => this.$store.commit('snackbar/open', "Copied to clipboard."))
    },
  },
});
