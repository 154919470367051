

























































































































import Vue from 'vue';
import { each as _each } from 'lodash';
import { Job } from '@/store/models';

export default Vue.extend({
  name: 'HskTasks',
  mounted() {
    _each(this.jobs, key => this.loadJobDetails(key));
  },
  computed: {
    jobs() {
      return this.$store.getters['adm/slots'];
    },
    jobDetails() {
      return this.$store.getters['adm/admKeys'];
    },
    activeJobs() {
      return this.$store.getters['adm/activeJobs'];
    },
  },
  watch: {
    activeJobs: function(newJobs, oldJobs) {
      _each(newJobs, (job: Job, key: string) => {
        if (oldJobs[key] !== null && oldJobs[key].id === newJobs[key].id) {
          return;
        }
        if (job !== null && job.status !== 'finished' && job.status !== 'failed') {
          const pollJob = () => {
            this.$store.dispatch('adm/taskStatus', key)
              .then(res => {
                if (res.status !== 'finished' && res.status !== 'failed') {
                  setTimeout(pollJob, 1000);
                }
                else {
                  this.loadJobDetails(key);
                }
              })
              .catch(err => this.$store.commit('snackbar/showError', err));
          };
          setTimeout(pollJob, 50);
        }
      })
    }
  },
  methods: {
    loadJobDetails(key: string) {
      this.$store.dispatch('adm/admResults', key)
        .catch(err => {
          if (!err.response || err.response.status !== 404) {
            this.$store.commit('snackbar/showError', err);
          }
        });
    },
    startJob(key: string) {
      this.$store.dispatch('adm/runTask', key)
        .catch(err => {
          this.$store.commit('snackbar/showError', err);
        });
    },
    taskButtonClass(key: string) {
      return { 
        'green lighten-1': this.activeJobs[key]?.status === 'finished', 
        'red lighten-1': this.activeJobs[key]?.status === 'failed' 
      }
    },
  }
});
