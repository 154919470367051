







































import Vue from 'vue';

import { Container, UploadTag } from '../store/models';
import { pullCmd, libraryUrl, singularityCmd } from '@/util/pullCmds';
import ContainerType from '@/components/ContainerType.vue';

export default Vue.extend({
  name: 'LatestContainers',
  components: { ContainerType },
  mounted() {
    this.loadLatest();
  },
  computed: {
    latest() {
      return this.$store.getters['containers/latest'];
    },
  },
  methods: {
    loadLatest() {
      this.$store.dispatch('containers/latest')
        .catch(err => this.$store.commit('snackbar/showError', err));
    },
    copyTag: function(container: Container, tag: UploadTag) {
      let prom: Promise<any>;
      if (tag.manifestType) {
        prom = this.$copyText(pullCmd({ path: container.fullPath, type: tag.manifestType }, tag.name));
      }
      else if (tag.imageType && tag.imageType === 'singularity') {
        prom = this.$copyText(`${singularityCmd()} pull library://${libraryUrl({ path: container.fullPath }, tag.name)}`)
      }
      else {
        return;
      }
      prom.then(() => this.$store.commit('snackbar/open', "Copied to clipboard."));
    },
  },

});
