
























































































































































































































import Vue from 'vue';
import { Entity, User, checkName } from '../store/models';
import { clone as _clone } from 'lodash';
import UserInput from '@/components/UserInput.vue';

interface State {
  search: string;
  editItem: Entity;
  showEdit: boolean;
  editValid: boolean;
  showDelete: boolean;
  sortBy: string;
  sortDesc: boolean;
}

function defaultItem(): Entity {
  const item = new Entity();
  item.createdAt = new Date();
  return item;
}

export default Vue.extend({
  name: 'HskEntities',
  components: { 'hsk-user-input': UserInput },
  mounted() {
    this.loadEntities();
  },
  data: (): { localState: State; sortKeys: { key: string; desc: string }[] } => ({
    localState: {
      search: '',
      showEdit: false,
      showDelete: false,
      editItem: defaultItem(),
      editValid: true,
      sortBy: 'name',
      sortDesc: false,
    },
    sortKeys: [
      { key: 'name', desc: 'Name' }, 
      { key: 'createdAt', desc: "Create Date" }, 
      { key: 'updatedAt', desc: "Last Updated" },
      { key: 'size', desc: '# Collections' },
      { key: 'usedQuota', desc: 'Size' },
    ]
  }),
  computed: {
    entities(): Entity[] {
      return this.$store.getters['entities/list'];
    },
    loading(): boolean {
      return this.$store.getters['entities/status']==='loading';
    },
    editTitle(): string {
      return this.localState.editItem.id ? 'Edit Entity' : 'New Entity';
    },
    currentUser(): User {
      return this.$store.getters.currentUser;
    },
  },
  watch: {
    'localState.showEdit': function showEdit(val) {
      if (!val) {
        this.closeEdit();
      }
    },
    'localState.showDelete': function showDelete(val) {
      if (!val) {
        this.closeDelete();
      }
    },
  },
  methods: {
    loadEntities() {
      this.$store.dispatch('entities/list')
        .catch(err => this.$store.commit('snackbar/showError', err));
    },
    editEntity(entity: Entity) {
      this.localState.editItem = _clone(entity);
      this.localState.showEdit = true;
    },
    deleteEntity(entity: Entity) {
      this.localState.editItem = _clone(entity);
      this.localState.showDelete = true;
    },
    deleteEntityConfirm() {
      this.$store.dispatch('entities/delete', this.localState.editItem)
        .then(() => this.$store.commit('snackbar/showSuccess', "It's gone!"))
        .catch(err => this.$store.commit('snackbar/showError', err));
    },
    closeEdit() {
      this.localState.showEdit = false;
      this.$nextTick(() => {
        this.localState.editItem = defaultItem();
      });
    },
    closeDelete() {
      this.localState.showDelete = false;
      this.$nextTick(() => {
        this.localState.editItem = defaultItem();
      });
    },
    save() {
      const action = this.localState.editItem.id ? 
        'entities/update' : 'entities/create';
      this.$store.dispatch(action, this.localState.editItem)
        .then(() => this.$store.commit('snackbar/showSuccess', 'Yay!'))
        .catch(err => this.$store.commit('snackbar/showError', err));
      this.closeEdit();
    },
    checkName(name: string): string | boolean {
      return checkName(name);
    },
    entityIcon(entity: Entity): string {
      if (entity.isGroup) {
        return 'mdi-account-group';
      }
      else if (entity.createdBy == this.currentUser.username) {
        return 'mdi-account';
      }
      else {
        return '';
      }
    }
  },
});
