


















import Vue from 'vue';

import { User } from '../store/models';

export default Vue.extend({
  name: 'HinkUserInput',
  props: {
    value: {
      type: String,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Username',
    },
  },
  data: () => ({
    prevSearch: '',
  }),
  computed: {
    loading(): boolean {
      return this.$store.getters['users/status'] === 'loading';
    },
    result(): User[] {
      return this.$store.getters['users/searchResult'];
    },
  },
  methods: {
    search(val: string) {
      if (this.prevSearch !== val && !this.loading) {
        this.$store.dispatch('users/search', { username: val || this.value })
          .catch(err => this.$store.commit('snackbar/showError', err));
        this.prevSearch=val;
      }
    },
  }
})
