






































































































import Vue from 'vue';
import { getEnv } from '@/util/env';

import { AxiosError } from 'axios';
import { User } from '@/store/models';
import { generateMsg } from '@/store/modules/snackbar';

interface State {
  user: {
    username: string;
    password: string;
  };
  newUser: User;
  tab: number;
  canSubmit: boolean;
  loginError: string;
  canRegister: boolean;
  passwordVisible: boolean;
  password1: string;
  password2: string;
  registerSuccess: boolean;
  registerError: string;
}

interface Data {
  localState: State;
  rules: Array<(val: string) => boolean | string>;
}

export default Vue.extend({
  name: 'HskLogin',
  data: (): Data => ({
    localState: {
      tab: 0,
      user: {
        username: '',
        password: '',
      },
      newUser: new User(),
      canSubmit: false,
      loginError: '',
      passwordVisible: false,
      password1: '',
      password2: '',
      canRegister: false,
      registerSuccess: false,
      registerError: '',
    },
    rules: [
      (v: string): boolean | string => !!v || 'Required!',
    ],
  }),
  methods: {
    doRegister() {
      this.localState.registerError = '';
      this.localState.registerSuccess = false;
      this.localState.newUser.password=this.localState.password1;
      this.$store.dispatch('users/register', this.localState.newUser)
        .then(() => {
          this.localState.registerError = '';
          this.localState.registerSuccess = true;
        })
        .catch(err => {
          this.localState.registerSuccess = false;
          this.localState.registerError = generateMsg(err);
        });
    },
    doLogin() {
      this.$store.dispatch('requestAuth', this.localState.user)
        .then(() => {
          if (this.$store.getters['currentUser'].isAdmin) {
            this.$store.dispatch('adm/ldapStatus', { reload: true });
          }
          this.$router.push('/');
        })
        .catch((err: AxiosError) => {
          this.localState.loginError = generateMsg(err);
        });
    }
  },
  computed: {
    loading(): boolean {
      return this.$store.getters.authStatus === 'loading';
    },
    registerEnabled(): boolean {
      return getEnv('VUE_APP_ENABLE_REGISTER') as boolean
    },
    passwordsMatching(): boolean {
      return this.localState.password1 != '' && this.localState.password2 != '' &&
        this.localState.password1 === this.localState.password2;
    },
  }
});
