
































































import { Image, InspectAttributes, } from '@/store/models';
import SingularityDetails from '@/components/SingularityDetails.vue';
import Vue from 'vue';

interface State {
  meta: InspectAttributes;
  showDef: boolean;
}

export default Vue.extend({
  components: { SingularityDetails },
  name: 'ImageDetails',
  props: {
    image: {
      type: Image,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data: (): { localState: State } => ({
    localState: {
      meta: {
        deffile: '',
      },
      showDef: false,
    },
  }),
  computed: {
  },
  methods: {
    copyTag(tag: string) {
      this.$copyText(`library://${this.image.pullUrl(tag)}`)
        .then(() => this.$store.commit('snackbar/showSuccess', "Copied to clipboard."));
    },
    inspect() {
      this.$store.dispatch('images/inspect', this.image)
        .then(response => {
          console.log(response);
          this.localState.meta = response;
          this.localState.showDef = true;
        })
        .catch(err => {
          this.$store.commit('snackbar/showError', err);
        });
    },
  }
});
