
















































































































import Vue from 'vue';
import { User } from '../store/models';

import { clone as _clone } from 'lodash';

interface State {
  editUser: User | null;
  editValid: boolean;
  showPwChange: boolean;
  oldPassword: string;
  password1: string;
  password2: string;
  pwChangeValid: boolean;
}

export default Vue.extend({
  name: 'HskAccount',
  data: (): { localState: State } => ({
    localState: {
      editUser: null,
      editValid: true,
      showPwChange: false,
      oldPassword: '',
      password1: '',
      password2: '',
      pwChangeValid: true,
    },
  }),
  mounted: function() {
    this.localState.editUser = _clone(this.$store.getters.currentUser);
  },
  computed: {
    passwordsMatching(): boolean {
      return this.localState.password1 != '' && this.localState.password2 != '' &&
        this.localState.password1 === this.localState.password2;
    },
    oldPasswordMissing(): boolean {
      return !this.localState.oldPassword;
    },
  },
  watch: {
    'localState.showPwChange': function showPwChange(val) {
      if (!val) {
        this.closePwChange();
      }
    },
  },
  methods: {
    reset() {
      this.localState.editUser = _clone(this.$store.getters.currentUser);
    },
    update() {
      this.$store.dispatch('users/update', this.localState.editUser)
        .then(user => {
          this.$store.commit('setUser', user);
          this.localState.editUser = _clone(this.$store.getters.currentUser);
          this.$store.commit('snackbar/showSuccess', "Hooray!");
        })
        .catch(err => this.$store.commit('snackbar/showError', err));
    },
    changePassword() {
      const user = _clone(this.$store.getters.currentUser);
      user.oldPassword = this.localState.oldPassword;
      user.password = this.localState.password1;
      this.$store.dispatch('users/update', user)
        .then(user => {
          this.$store.commit('setUser', user);
          this.$store.commit('snackbar/showSuccess', 'Hooray!');
          this.closePwChange();
        })
        .catch(err => this.$store.commit('snackbar/showError', err));
    },
    closePwChange() {
      this.localState.showPwChange=false;
      this.localState.oldPassword='';
      this.localState.password1='';
      this.localState.password2='';
    },
  }
});
