
















































































































import { Image, User, checkName } from '@/store/models';
import Vue from 'vue';

import { clone as _clone, filter as _filter, concat as _concat } from 'lodash';

interface State {
  newTag: string;
  newTagValid: boolean;
  showAddTag: boolean;
  showDelete: boolean;
  image?: Image;
}

export default Vue.extend({
  name: 'SingularityDetails',
  props: {
    image: {
      type: Image,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data: (): { localState: State } => ({
    localState: {
      newTag: '',
      showAddTag: false,
      showDelete: false,
      newTagValid: true,
      image: undefined,
    }
  }),
  mounted: function() {
    this.localState.image = this.image;
  },
  computed: {
    currentUser(): User {
      return this.$store.getters.currentUser;
    },
    canEdit(): boolean {
      return !this.readonly && (this.localState.image?.canEdit ?? false)
    },
  },
  watch: {
    'localState.showAddTag': function showAddTag(val) {
      if (!val) {
        this.closeShowAddTag();
      }
    },
  },
  methods: {
    deleteTag(tag: string) {
      const updateImage = _clone(this.image);
      updateImage.tags = _filter(updateImage.tags, t => t !== tag);
      this.saveTags(updateImage)
        .then(() => this.$store.commit('snackbar/showSuccess', 'Tag removed.'))
    },
    addTag() {
      const updateImage = _clone(this.image);
      updateImage.tags = _concat(updateImage.tags, this.localState.newTag);
      this.saveTags(updateImage)
        .then(() => {
          this.$store.commit('snackbar/showSuccess', 'Tag added');
          this.closeShowAddTag();
        });
    },
    saveTags(updateImage: Image): Promise<any> {
      return this.$store.dispatch('images/updateTags', updateImage)
        .catch(err => this.$store.commit('snackbar/showError', err));
    },
    deleteImage() {
      this.$store.dispatch('images/delete', this.image)
        .catch(err => {
          this.$store.commit('snackbar/showError', err);
        });
    },
    closeShowAddTag() {
      this.localState.showAddTag = false;
      this.$nextTick(() => {
        this.localState.newTag = '';
      });
    },
    checkTag(name: string): string | boolean {
      if (name === '' || name === undefined) {
        return "Required";
      }
      else {
        return checkName(name);
      }
    },
  }
});
