











import Vue from 'vue';
import { Container } from '@/store/models';

export default Vue.extend({
  props: {
    container: {
      type: Container,
      required: true,
    },
  }
});
