


























import { Container } from '@/store/models';
import Vue from 'vue';

export default Vue.extend({
  name: 'StarredContainers',
  mounted() {
    this.loadStarred();
  },
  computed: {
    containers(): Container[] {
      return this.$store.getters['users/starred'];
    }
  },
  methods: {
    loadStarred() {
      this.$store.dispatch('users/getStarred')
        .catch(err => this.$store.commit('snackbar/showError', err));
    },
    copyTag(container: Container) {
      this.$copyText(`library://${container.fullPath}`)
        .then(() => this.$store.commit('snackbar/open', 'Copied to clipboard'));
    },
  }
});
