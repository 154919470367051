




















































































































































































import { User } from '@/store/models';
import Vue from 'vue';
import { DataTableHeader } from 'vuetify';
import { cloneDeep as _cloneDeep, find as _find } from 'lodash';

interface State {
  search: string;
  sortBy: string;
  sortDesc: boolean;
  editItem: User;
  showEdit: boolean;
  showDelete: boolean;
  passwordVisible: boolean;
  password1: string;
  password2: string;
  editValid: boolean;
}

function defaultItem(): User {
  const user = new User();
  user.createdAt = new Date();
  user.isActive = true;
  user.isAdmin = false;
  user.source = 'local';
  return user;
}

export default Vue.extend({
  name: 'HskUsers',
  mounted() {
    this.loadUsers().then(() => this.openEdit(this.$route.query.id));
  },
  data: (): { headers: DataTableHeader[]; localState: State } => ({
    headers: [
      { text: 'id', value: 'id', sortable: true, filterable: false, width: '3%' },
      { text: 'Username', value: 'username', sortable: true, filterable: true, width: '15%' },
      { text: 'Email', value: 'email', sortable: true, filterable: true, width: '15%' },
      { text: 'Name', value: 'lastname', sortable: true, filterable: true, width: '', },
      { text: 'Admin', value: 'isAdmin', sortable: true, filterable: false, width: '5%' },
      { text: 'Active', value: 'isActive', sortable: true, filterable: false, width: '5%' },
      { text: 'Source', value: 'source', sortable: true, filterable: true, width: '5%' },
      { text: 'Actions', value: 'actions', sortable: false, filterable: false, width: '9%' },
    ],
    localState: {
      search: '',
      sortBy: 'id',
      sortDesc: false,
      editValid: true,
      editItem: defaultItem(),
      showEdit: false,
      showDelete: false,
      passwordVisible: false,
      password1: '',
      password2: '',
    },
  }),
  computed: {
    users() {
      return this.$store.getters['users/list'];
    },
    loading() {
      return this.$store.getters['users/status']==='loading';
    },
    editTitle(): string {
      return this.localState.editItem.id ? 'Edit User' : 'Create User';
    },
    passwordsMatching(): boolean {
      return this.localState.password1 === this.localState.password2;
    },
    currentUser(): User {
      return this.$store.getters['currentUser'];
    },
  },
  watch: {
    'localState.showEdit': function showEdit(val) {
      if (!val) {
        if (this.$route.query.id) {
          this.$router.push({ query: { id: undefined }});
        }
        this._closeEdit();
      }
    },
    'localState.showDelete': function showDelete(val) {
      if (!val) {
        this.closeDelete();
        this.$nextTick(() => {
          this.localState.editItem = defaultItem();
        });
      }
    },
    '$route.query.id': function openEditUser(id) {
      this.openEdit(id);
    },
  },
  methods: {
    loadUsers(): Promise<User[]> {
      return this.$store.dispatch('users/list')
        .catch(err => this.$store.commit('snackbar/showError', err));
    },
    openEdit(id: string | (string | null)[]) {
      if (id) {
        const user = _find(this.users, u => u.id === id || u.username === id);
        if (user) {
          this._editUser(user);
        }
        else {
          console.log(`${id} not found`);
        }
      }
      else {
        this.localState.showEdit=false;
      }
    },
    closeEdit() {
      this.localState.showEdit = false;
    },
    _closeEdit() {
      this.$nextTick(() => {
        this.localState.editItem = defaultItem();
        this.localState.password1 = '';
        this.localState.password2 = '';
      });
    },
    editUser(user: User) {
      this.$router.push({ query: { id: user.id }});
    },
    _editUser(user: User) {
      this.localState.editItem = _cloneDeep(user);
      this.localState.showEdit = true;
    },
    deleteUser(user: User) {
      this.localState.editItem = _cloneDeep(user);
      this.localState.showDelete = true;
    },
    closeDelete() {
      this.localState.showDelete = false;
    },
    save() {
      const action = this.localState.editItem.id ?
        'users/update' : 'users/create';

      if (this.localState.password1 && this.passwordsMatching===true) {
        this.localState.editItem.password = this.localState.password1;
      }
      this.$store.dispatch(action, this.localState.editItem)
        .then(() => this.$store.commit('snackbar/showSuccess', 'Hoorary!'))
        .catch(err => this.$store.commit('snackbar/showError', err));
      this.closeEdit();
    },
    deleteConfirm() {
      this.$store.dispatch('users/delete', this.localState.editItem)
        .then(() => this.$store.commit('snackbar/showSuccess', "It's gone!"))
        .catch(err => this.$store.commit('snackbar/showError', err));
      this.closeDelete();
    },
  },
});
