








































































import Vue from 'vue';

// :append-icon="localState.static ? 'mdi-lock-outline' : null"

import { clone as _clone } from 'lodash';

interface State {
  status: string;
  value: string;
  static: boolean;
}

export default Vue.extend({
  name: 'HinkTextInput',
  props: {
    type: {
      type: String,
      default: "text",
    }, 
    label: {
      type: String,
      required: true,
    },
    field: String,
    obj: Object,
    action: String,
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    staticValue: {
      type: [ String, Boolean, Number ],
      default: undefined,
    },
    check: {
      type: Array,
      default: () => undefined,
    },
    options: {
      type: Array,
      default: () => [],
    },
    inline: {
      type: Boolean,
      default: false,
    }
  },
  data: function(): { localState: State } {
    return {
      localState: {
        status: '',
        value: this.obj && this.field ? this.obj[this.field] : this.staticValue,
        static: this.staticValue !== undefined
      }
    };
  },
  computed: {
    rules(): Array<(val: string) => boolean | string> {
      let rules: Array<(val: string) => boolean | string> = [];
      if (this.required) {
        rules = rules.concat(
          val => !!val || 'Required!'
        )
      }
      if (this.check) {
        rules = rules.concat(this.check as any);
      }
      return rules;
    }
  },
  watch: {
    field() {
      if (this.obj && this.field) {
        this.localState.value=this.obj[this.field];
      }
    },
    obj() {
      if (this.obj && this.field) {
        this.localState.value=this.obj[this.field];
      }
    },
    staticValue(newVal) {
      this.localState.value=newVal;
      this.localState.static = newVal !== undefined;
    },
  },
  methods: {
    saveValue() {
      const obj = _clone(this.obj);
      obj[this.field] = this.localState.value;
      if (!this.action) {
        return this.$emit('updated', obj);
      }
      this.localState.status='saving';
      this.$store.dispatch(this.action, obj)
        .then(updated => {
          this.localState.status='success';
          this.$emit('updated', updated);
        })
        .catch(err => {
          this.localState.status='failed';
          this.$store.commit('snackbar/showError', err);
        });
    }
  },
})
