














































import { SearchResult } from '@/store/models';
import Vue from 'vue';

import { flatten as _flatten, map as _map, set as _set } from 'lodash';

interface State {
  search: string | null;
  select: string | null;
}

export default Vue.extend({
  name: 'TopBar',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  data: function(): { localState: State } {
    return {
      localState: {
        search: null,
        select: null,
      }
    }
  },
  computed: {
    isLoggedIn(): boolean {
      return this.$store.getters.isLoggedIn;
    },
    loading(): boolean {
      return this.$store.getters['search/status'] === 'loading';
    },
    result(): any[] {
      const res = this.$store.getters['search/results'] as SearchResult;
      const ret = res ? _flatten([
        [{ header: 'Entities' }],
        _map(res.entity, e => ({ text: e.name, value: _set(e, 'ctype', 'Entity') })) as any,
        [{ header: 'Collections' }],
        _map(res.collection, e => ({ text: e.name, value: _set(e, 'ctype', 'Collection') })) as any,
        [{ header: 'Containers' }],
        _map(res.container, e => ({ text: e.name, value: _set(e, 'ctype', 'Container') })) as any,
        [{ header: 'Images' }],
        _map(res.image, i => ({ text: i.containerName, value: _set(i, 'ctype', 'Image') })) as any,
      ]) : [];
      return ret;
    },
  },
  watch: {
    'localState.search': function searchState(val: string) {
      if (val) {
        if (val !== this.localState.select && !this.loading) {
          this.search(val);
        }
      }
      else {
        this.$store.commit('search/setResults', null);
      }
    },
  },
  methods: {
    search(val: string) {
      this.$store.dispatch('search/any', { name: val })
        .catch(err => this.$store.commit('snackbar/showError', err));
    },
    navigate(obj: any) {
      if (!obj) {
        return;
      }
      switch(obj.ctype) {
        case 'Entity':
          this.$router.push({ name: 'EntityCollections', params: { entity: obj.entityName }})
          break;
        case 'Collection':
          this.$router.push({ name: 'Containers', params: { entity: obj.entityName, collection: obj.collectionName }})
          break;
        case 'Container':
        case 'Image':
          this.$router.push({ name: 'ContainerDetails', params: { entity: obj.entityName, collection: obj.collectionName, container: obj.containerName }})
          break;
      }
    },
    toggleDrawer() {
      this.$store.commit('toggleDrawer');
    },
  },
});
